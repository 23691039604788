import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Helmet } from "react-helmet"

import background from "../images/bv-hero.png"
import Layout from "../components/Layout"
import "../components/Layout.css"
import Seo from "../components/Seo"

//styles
const Feature = styled.div`
  a {
    display: inline-block;
  }

  h1 {
    color: rgba(34, 71, 230, 1);
    transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);

    @media (max-width:640px) {
      line-height: 1.1
    }
  }
`

const FeatureBoxWrapper = styled.div`
  overflow: hidden;
`

const FeatureBox = styled.div`
  background: url(${background});
  background-position: center;
  background-size: cover;
  height: 50vh;
  margin-top: 12px;
  border-radius: 18px;
  animation: BackgroundZoomOut 1s ease;
  transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);
  overflow: hidden;
  ${'' /* transition: transform 1s; */}

  :hover {
    ${'' /* transform: scale(1.05); */}
    animation: BackgroundZoomIn 1s ease;
    overflow: hidden;

  }
`
const BackArrow = styled.div`
  width: 57px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;

  :hover i, :hover p {
    color: rgba(60, 200, 215, 1);
  }  

  i {
    content: "\f007";
    font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
    color: rgba(0, 0, 0, .78);
    font-style: normal;
  }

  p {
    font-family: 'Alpino-Bold', 'Arial', 'Helvetica', 'sans-serif';
    font-size: 19.2px;
    margin: 0;
  }

  @keyframes BackgroundZoomIn {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes BackgroundZoomOut {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`

const Introduction = styled.div`
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5vw;

  a {
    font-weight: 500;
    color: rgba(34, 71, 230, 1);
  }

  a:hover {
    color: rgba(60, 200, 215, 1);
  }

  @media (max-width:640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const ProjectOverViewWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectOverview = styled.div`
`
const ProjectDetailsWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectDetails = styled.div`
  hr {
    opacity: 0;

    @media (max-width:640px) {
      opacity: 1;
      padding-top: 24px;
    }
  }

  @media (max-width:640px) {
    margin-top: 24px;
  }
`

const ProjectDescription = styled.div`
  p {
    margin-right: 15vw;

    @media (max-width:640px) {
      margin-right: auto;
    }
  }

  ul {
    list-style: none;
  }

  ul li::before {
    content: "✅";
    display: inline-block;
    margin-right: 0.2rem;
    list-style-position: outside;
  }

  ol p::before {
    content: "👍🏼";
    display: inline-block;
    margin-right: 0.2rem;
    margin-left: 2rem;
    list-style-position: outside;
    width: auto;
    height: auto;
    background: none;
  }

  ol p {
    font-family: Alpino-Medium, Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 21px;
    margin-top: 0;
  }

  ol {
    list-style: none;
    counter-reset: steps;
  }

  ol li {
    counter-increment: steps;
  }

  ol li::before {
    content: counter(steps);
    margin-right: 0.5rem;
    background: rgba(0, 0, 0, .78);
    color: white;
    width: 1.4em;
    height: 1.2em;
    border-radius: 60%;
    display: inline-grid;
    place-items: center;
    line-height: 1.2em;
    padding-bottom: 3px;
  }

  li {
    font-family: Alpino-Medium, Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 21px;
  }

  img {
    width: 100%;

    @media (max-width:640px) {
      width: 100%;
    }
  }
`

const ImageDuo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.5vw;

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }

  img {
    width: 100%;
  }
`

const ScrollOnUp = styled.div`
  display: block;

  :hover i, :hover p {
      color: rgba(60, 200, 215, 1);
  }
`

const ProjectWrapper = styled.div`
  max-width: 1344px;
  margin: 0 auto;
`

const Lists = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: top;
  ${'' /* grid-gap: 1.75vw; */}
  margin-top: 24px;

  @media (max-width:640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const List = styled.div`
  h3 {
    margin-top: 0;
  }
`

const bv = () => (
    <Layout>
      <Seo title="Design System" />
        <Helmet>
            <script src="https://kit.fontawesome.com/077761ae24.js" crossOrigin="anonymous"></script>
        </Helmet>
        <div className="Hero">
        <div id="top"/>
        <ProjectWrapper>
            <Feature>
            <Link to="/work">
                <BackArrow>
                <i className="fas fa-long-arrow-alt-left"></i>
                <p>back</p>
                </BackArrow>
            </Link>
            <h1>BaleVision™ Design System</h1>
            <FeatureBoxWrapper>
                <FeatureBox />
            </FeatureBoxWrapper>
            </Feature>
            <Introduction>
            <ProjectOverViewWrapper>
                <ProjectOverview>
                <h2>Project Overview</h2>
                <h4>When merQbiz® spun up their data analytics product, known as BaleVision™, it was an opportunity for a fresh approach to a completely new design system. The following project outlines some of this work, the design thinking behind the refresh, as well as some learnings.</h4>
                <p>This was a very rewarding project to establish the initial and ongoing visual language and develop a visual style guide and design system.</p>
                </ProjectOverview>
            </ProjectOverViewWrapper>
            <ProjectDetailsWrapper>
                <ProjectDetails className="projectDetails">
                <hr></hr>
                <h5 className="details">Credits</h5>
                <p className="details">Research and guidance from <a href="https://www.linkedin.com/in/sarasaito/" target="_blank" rel="noreferrer">Sara Saito</a>, Design System learnings from <a href="https://www.linkedin.com/in/travis-radtke-6b89042a/" target="_blank" rel="noreferrer">Travis Radke</a>, and <a href="https://www.linkedin.com/in/cliffpyles/" target="_blank" rel="noreferrer">Cliff Pyles</a>. All design styles created by yours truly and built by <a href="https://www.linkedin.com/in/reikurata/" target="_blank" rel="noreferrer">Rei Kurata</a> and <a href="https://www.linkedin.com/in/kirk-chu-0abb3234/" target="_blank" rel="noreferrer">Kirk Chu</a>.</p>
                </ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Client</h5>
                <p className="details">merQbiz®</p>
                </ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">agency</h5>
                <p className="details">In-house start-up</p>
                </ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Deliverable</h5>
                <p className="details">Custom Design System</p>
                </ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Goal</h5>
                <p className="details">Scale design by creating reusable UI tokens.</p>
                </ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">RESULTS</h5>
                <p className="details">A user-friendly design system that stretched the1 reflected strong brand characteristics. </p>
                </ProjectDetails>
            </ProjectDetailsWrapper>
            </Introduction>
            <ProjectDescription className="projectChapter">
            <h2>Discovery</h2>
            <h3>Building Upon a Foundation</h3>
            <p>One of the early challenges involved investigating how we could build upon existing design tokens and keep strong branding that was part of the merQbiz® eCommerce platform. There was a desktop application that was content-heavy, rigid in grid, and relied on a more conservative and generic typeface. Then there was the mobile app, which was sparse with overly-simplified UI patterns, a more saturated color palette, and with a typeface that was rounded and wide-spaced.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/bv-magento-desktop.png"
                    alt="Sample screen of the eCommerce desktop platform"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                    loading="eager"
                />
                <StaticImage
                    src="../images/bv-magento-mobile.png"
                    alt="Sample screens of the eCommerce mobile platform"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
            </ImageDuo>
            <div className="imgcaption">Screengrabs of the desktop and mobile apps side-by-side showing two incohesive design tokens within an enterprise B2B platform.</div>
            <h3>Sensing Visual Evolution</h3>
            <p>At the time, merQbiz® eCommerce platform was three years mature, and there were signs of progression within the visual design of marketing materials. The year prior to this project, there was a marketing website redesign, which introduced more visual energy with a lot more color and the use of linear color gradient patterns. I designed some of the first collateral print pieces and ran with design principles such as the use of gradients, more imagery, succinct content, and more visual emotion.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/bv-magento-website.png"
                    alt="Sample screen of the marketing website with a big blue gradient in the top left corner"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                    loading="eager"
                />
                <StaticImage
                    src="../images/bv-magento-onesheet.png"
                    alt="Sample screen of marketing collateral showing knocked-out white copy on a solid gradient and image background"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                    loading="eager"
                />
            </ImageDuo>
            <div className="imgcaption">Screengrabs showing the use of linear gradients to add energy to marketing communications and emphasize the same whitespace while using color.</div>
            <h3>Elephant in the Room: Maintain 2-3 systems, or unite as new</h3>
            <p>In the beginning, there were two designers working on this project, and we started by auditing existing design tokens, type and color. I focused on mobile app and marketing website design tokens, which were built with React native and React with Grommet respectively, and were limited in functionality. eCommerce desktop design tokens were specific to eCommerce, and any leap over to mobile would involve duplicate efforts. The important decision was made as a team to focus on one design system for web and mobile going forward. This would allow us to research existing design systems for both, and essentially build upon that system quickly with unique visual designs.</p>
            <h3>Comparative Research</h3>
            <p>We examined dozens and dozens of existing design systems, many open source, creating a research matrix with UI components per system that would save our team time. A shortlist was created from our research, which we began stress testing. The process was to quickly import a system's design tokens and rebuild existing key screens using primarily those design tokens. In the end, we decided to start with Shopify's <a href="https://polaris.shopify.com/" target="_blank" rel="noreferrer">Polaris</a> Design system, which had good eCommerce functionality, but also included visualization components and excellent documentation. Next steps were to create an approach to visually design components.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/bv-design-a-page.png"
                    alt="Note paper with lists of desktop and mobile design systems and pages to try to build out"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                    loading="eager"
                />
                <StaticImage
                    src="../images/bv-nativebase-samp.png"
                    alt="Sample design of the mobile app using design tokens from NativeBase, a React Native design library"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                    loading="eager"
                />
            </ImageDuo>
            <div className="imgcaption">A sample testing sheet as we stress-tested design tokens from various design systems. On the right is an example of a page quickly created using NativeBase.</div>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Observations & Hypothesis</h2>
            <h3>Content Pyramid for Type</h3>
            <p>I naturally started with casting type as this is a key ingredient in any visual style. I developed within our design language a hierarchy of expressions in the form of a content pyramid. I identified four main characteristics of existing content, and we ranked them in order of priority related to product, brand, and business needs. This helped eliminate the typeface Gotham Rounded, as for example it was too extended or wide in appearance to meet "concise" expectations.</p>
            <StaticImage
                src="../images/bv-content-pyramid.png"
                alt="Diagram of the content pyramid with Clarity as a base building block and Personality on the top"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="fullWidth"
                loading="eager"
            />
            <div className="imgcaption">While some products have a lot of personality, for data products, personality can lead to future translation and cultural concerns and was deemed not as important.</div>
            <h3>Design System Principles</h3>
            <p>I led workshops with marketing to determine three key traits that would become the foundational principles of our design system. These were traits, *Confidence*, *Convenience*, and *Accessiblity*, and existed such that we could use them to categorically critique any piece of product design. Does this button feel or look confident? Is that type convenient to read? Is it legible? Is presentation of data accessible to all? Are we considering color vision accessibility? In this phase, we ruled out another typeface Helvetica Neue for its general lack of confidence compared to more modernly drawn type that were less ubiquitous.</p>
            <StaticImage
                src="../images/bv-design-principles.png"
                alt="Graphic showing three design principles as they apply to letterforms"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="fullWidth"
                loading="eager"
            />
            <div className="imgcaption">Here the design system principles are used to evaluate type, but the these three principles would be baked into everything designed.</div>
            <h3>Type Studies</h3>
            <p>With a background in typography, I created a series of type studies which were pasted up around the office for feedback. Initially elements like character shape, font weights, legibility were compared. Serious contenders were further studied while other typefaces were introduced from competitive products for analysis. As we narrowed down the shortlist, studies like below were put together to test type on GUI elements, observe numerical alignment, and essentially check if the type had legs.</p>
            <StaticImage
                src="../images/bv-type-study.png"
                alt="Type studies examining wordmark, headline, paragraph copy, call to action use, marketing graphic, form field use, and tooltip for two type specimens"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="fullWidth"
                loading="eager"
            />
            <div className="imgcaption">Euclid Triangle, a typeface from <a href="https://www.swisstypefaces.com/" target="_blank" rel="noreferrer">Swiss Typefaces</a> was the chosen in the end for its blend of strong geometry, perk of non-Oldstyle numerals with good tabular alignment, and artful intrigue.</div>
            <h3>Color Studies</h3>
            <p>Good data visualization has a lot hues and color values for expression. I created color studies both to understand existing color palettes and to expand the number of secondary colors. merQbiz® had developed one primary and one secondary color, and it was clear more colors would be needed to express data visually. The website had expanded secondary colors by one, but still more colors were required. Using a combination of color theory and color tools, a series of palettes were designed.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/bv-mqb-colors.png"
                    alt="Blocks of colors withe labelling showing the background color, primary, and secondary colors for merQbiz eCommerce platform"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                    loading="eager"
                />
                <StaticImage
                    src="../images/bv-mqb-website-colors.png"
                    alt="Blocks of colors withe labelling showing the background color, primary, and secondary colors for merQbiz marketing website"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                    loading="eager"
                />
            </ImageDuo>
            <div className="imgcaption">Subtle differences were found between brand guidelines and colors used. On the left were colors used in the eCommerce product. On the right were colors used in the marketing website.</div>
            <StaticImage
                src="../images/bv-color-study.png"
                alt="A bunch of color studies at the top, with one in focus that shows colors planned for BaleVision™"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="fullWidth"
                loading="eager"
            />
            <div className="imgcaption">Early favorite color palette below a sampling of initial color studies. This palette focused on more vibrant colors with subtle hue changes across gradients.</div>
            <p>The new colors would relate to the existing colors from the website and merQbiz® brand, but would blend in new energy that was symbolic of the new BaleVision™ product. Multi-tonal gradients were introduced to make the color language more enticing.</p>
            <h3>Iconography</h3>
            <p>I evaluated iconography as a third area where the visual design could be updated. Instead of expression, the goal with icons would be to find a consistent visual language. Previous icons were flat and line-drawn and clean, but involved a mix of complexity and use of stylistic flourishes. Using geometric shapes as a base for all icons, I helped tighten up line thickness and style. This allowed us to expand icons to visually identify more complicated and abstract messaging.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/bv-old-icons.png"
                    alt="A layout of six icons from the eCommerce platform and marketing website that have a range of angular lines, rounded lines, and lines with different thicknesses"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                    loading="eager"
                />
                <StaticImage
                    src="../images/bv-icon-direction.png"
                    alt="A layout of six icons from BaleVision™ that have a consistent line thickness and rounded line characterstic"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                    loading="eager"
                />
            </ImageDuo>
            <div className="imgcaption">Initial icons on the left had a mix of square and rounded corners with inconsistency in use of flourishes and complexity. BaleVision™ iconography was based around the three design principles.</div>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Iterations</h2>
            <h3>Scale Fast</h3>
            <p>Using the Polaris UI kit as the base for UI components proved to be a real time saver, allowing us to build an initial mobile portal and the foundation for a desktop admin portal in a few sprints. However, as we began to get feedback from users, it was clear that we would have to do more than customize their UI Kit.</p>
            <h3>Refine and Test</h3>
            <p>One example of this was research from inspectors in the field were experiencing legibility issues on site. Add outside conditions of cold and rain with pour screen visibility, and perhaps safety goggles obstructing glasses, and inspectors were haven't trouble reading copy on screens. We went back and began refining design elements like font size, weight, and constrast to add clarity to the user experience.</p>
            <StaticImage
                src="../images/bv-progression.png"
                alt="Rows of UI elements that progress in legibility and user-friendliness"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="fullWidth"
                loading="eager"
            />
            <div className="imgcaption">Sample components as they progressed toward more legibility and visually friendliness. Note that elements began to move from brand color to usability color palette.</div>
            <h3>Adding an element of visual differentiation</h3>
            <p>One internal direction was to redesign components to look more "Business to Consumer" than a traditional enterprise product. While many screens included complex forms, there was inspiration from Neuomorphism to round corners, however I kept elements like tiles more traditional with less of a shape shadow than the norm. Skeuomorphic styles were reduced as well, however text space was kept large compared to order B2B products.</p>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Terminus</h2>
            <p>With several more components in the works at the termination of the product, here is a list of UI components. With these components, we were able to build a data analytics platform that could incorporate large amounts of data. There were standards for visualizations that were informed from the designs: colors, layout, UI orientation, and elements like labelling.</p>
            <Lists>
                <List>
                <h3>Actions/Navigation</h3>
                <ul>
                    <li>Accordion Controls</li>
                    <li>Breadcrumbs</li>
                    <li>Buttons</li>
                    <li>Dropdown menu</li>
                    <li>Links</li>
                    <li>Pagination</li>
                    <li>Segmented Controls</li>
                    <li>Scroll</li>
                    <li>Toggle Switches</li>
                </ul>
                </List>
                <List>
                <h3>Form Fields</h3>
                <ul>
                    <li>Check Box</li>
                    <li>Compound Radio/Select</li>
                    <li>Date Field</li>
                    <li>Drop Zone</li>
                    <li>Radio Button</li>
                    <li>Resource List</li>
                    <li>Search Field</li>
                    <li>Selects</li>
                    <li>Text Area</li>
                    <li>Text Field</li>
                    <li>Wizard Selector</li>
                </ul>
                </List>
                <List>
                <h3>Data Table</h3>
                <ul>
                    <li>Cell Button</li>
                    <li>Cell Checkbox</li>
                    <li>Cell Highlights</li>              
                    <li>Cell Icon Button</li>
                    <li>Cell Link</li>   
                    <li>Cell Tooltip</li>   
                    <li>Row Hover</li>
                    <li>Selects Cell</li>    
                    <li>Table Header</li>
                    <li>Table Header w/Sorting</li>
                    <li>Table Header Checkbox</li>
                    <li>Text Field Cell</li>
                </ul>
                </List>
                <List>
                <h3>Usability</h3>
                <ul>
                    <li>Alert</li>
                    <li>Dialog</li>
                    <li>Loader</li>
                    <li>Progress Bar</li>
                    <li>Receipt Banner</li>
                    <li>Snackbar</li>
                    <li>Toasts</li>
                    <li>Tooltip</li>
                </ul>
                </List>
                <List>
                <h3>Structure</h3>
                <ul>
                    <li>Flag</li>
                    <li>Header with Navigation</li>
                    <li>Marketing Media</li>
                    <li>Modal Structure</li>
                    <li>Sidebar Navigation</li>
                    <li>Spacing</li>
                    <li>Steps</li>
                    <li>Tag</li>
                    <li>Tile</li>
                </ul>
                </List>
            </Lists>
            <h3>Conclusions</h3>
            <p>Switching to Euclid Triangle gave both product and marketing a much more modernly drawn typeface and saved lofty royalty fees. Color palettes added the possbility for more emotion in UI and data visuals. Iconography refinements allowed us to be consistent in storytelling, such as concept animations you can see <Link to="/ltt/">here</Link>.</p>
            </ProjectDescription>
            <ScrollOnUp 
            onClick={() => scrollTo('#top')}>
            <div className="ScrollToTop">
                <p>Scroll to top</p>
                <i className="fas fa-angle-up"></i>
            </div>
            </ScrollOnUp>
        </ProjectWrapper>
        </div>
    </Layout>
  )

export default bv